import {MoonLoader} from "react-spinners";
import {useGetProductDetailsQuery} from "./ProductDetailsSlice";
import {Link, useParams} from "react-router-dom";
import {ProductCarousel} from "../../common/components/productDetail/ProductCarousel";
import {ProductInfoBar} from "../../common/components/productDetail/ProductInfoBar";
import {ProductGeneralInformation} from "../../common/components/productDetail/ProductGeneralInformation";
import {ProductSpecifications} from "../../common/components/productDetail/ProductSpecifications";
// import {ProductRelatedProducts} from "../../common/components/productDetail/ProductRelatedProducts";
import {ProductDownloads} from "../../common/components/productDetail/ProductDownloads";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {StringTranslation} from "../../common/utils/StringTranslation";
import {Translate} from "../../common/utils/Translate";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {setProductType} from "../productRangePage/ProductRangeSlice";
import {ProductTypeEnum} from "../../common/enums/ProductTypeEnum";
import {useAppDispatch} from "../../app/hooks";

const ProductDetailsPageContainer = () => {

    const dispatch = useAppDispatch();
    const {productId} = useParams<{ productId: string }>()
    const userData = useGetUserInfoQuery()

    const {activeLanguage} = userData?.data || {activeLanguage: null}

    const {data, isLoading} = useGetProductDetailsQuery({
        productId: productId,
        language: activeLanguage
    })

    const {data: translations} = useGetTranslationsQuery();

    if (!isLoading && data && activeLanguage) {

        return (
            <>
                <div className="p-product-details">

                    <div className="c-breadcrumbs">
                        <Link to={RouteEnum.PRODUCT_RANGE}><span>{StringTranslation('product-range', translations, activeLanguage)}</span></Link>
                        <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/></div>
                        {data.productFamily?.code === 'accessories' ? (
                            <Link to={RouteEnum.PRODUCT_RANGE} onClick={() => dispatch(setProductType(ProductTypeEnum.accessories))}><span>{StringTranslation('accessories', translations, activeLanguage)}</span></Link>
                        ):(
                            <Link to={RouteEnum.PRODUCT_RANGE} onClick={() => dispatch(setProductType(ProductTypeEnum.products))}><span>{StringTranslation('machines', translations, activeLanguage)}</span></Link>
                        )}
                        <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/></div>
                        <span>{Translate(data.label, activeLanguage)}</span>
                    </div>

                    <ProductCarousel data={data}/>
                    <ProductInfoBar data={data} translations={translations} activeLanguage={activeLanguage}/>

                    <div className="p-product-details__specification-info">
                        <ProductGeneralInformation data={data} translations={translations} activeLanguage={activeLanguage}/>
                        <ProductSpecifications data={data} activeLanguage={activeLanguage}/>
                    </div>

                    {/*<div className="p-product-details__product-sliders">*/}
                    {/*    <ProductRelatedProducts title={StringTranslation('accessoires', translations, activeLanguage)}/>*/}
                    {/*    <ProductRelatedProducts title={StringTranslation('similar-machines', translations, activeLanguage)}/>*/}
                    {/*</div>*/}

                    <div className="p-product-details__downloads">
                        <ProductDownloads data={data}
                                          title={StringTranslation('marketing-downloads', translations, activeLanguage)}
                                          activeLanguage={activeLanguage} type={'marketing'}/>
                        <ProductDownloads data={data}
                                          title={StringTranslation('technical-downloads', translations, activeLanguage)}
                                          activeLanguage={activeLanguage} type={'technical'}/>
                    </div>

                </div>
            </>
        )
    } else return (
        <div className={'c-loader'}>
            <MoonLoader color={'#E52713FF'}/>
        </div>
    )
}
export default ProductDetailsPageContainer;
