import {Searchbar} from "../../common/components/general/Searchbar";
import {CollectionItem} from "../../common/components/collections/CollectionItem";
import {mapCollection, useGetCollectionsQuery} from "./CollectionsSlice";
import {MoonLoader} from "react-spinners";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect} from "react";
import {CollectionInterface} from "../../common/interfaces/CollectionInterface";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {StringTranslation} from "../../common/utils/StringTranslation";

const CollectionsPageContainer = () => {

    const dispatch = useAppDispatch();
    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: translations} = useGetTranslationsQuery()

    const {data, isLoading} = useGetCollectionsQuery();

    useEffect(() => {
        if (data) {
            dispatch(mapCollection(data))
        }
    }, [data, dispatch])

    const {collections} = useAppSelector(state => state.collections);

    if (!isLoading) {
        return (
            <>
                <div className="p-collections">
                    <div className="p-collections__header">
                        <div className="c-breadcrumbs">
                            <span>{StringTranslation('collections', translations, activeLanguage)}</span>
                            <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/></div>
                            <span>{StringTranslation('overview', translations, activeLanguage)}</span>
                        </div>
                        <Searchbar activeLanguage={activeLanguage}/>
                    </div>

                    <div className="p-collections__content">

                        <div className="p-collections__content__header">
                            <h2>{collections.length} {StringTranslation('collections', translations, activeLanguage).toLowerCase()}</h2>
                        </div>

                        {collections.length > 0 &&
                            <div className="p-collections__content__collections">
                                {collections.map((collection: CollectionInterface, collectionIndex: number) => (
                                    <CollectionItem key={collectionIndex} collection={collection}/>
                                ))}
                            </div>
                        }

                    </div>
                </div>
            </>
        )
    }

    return (
        <div className={"c-loader"}>
            <MoonLoader color={"#E52713FF"}/>
        </div>
    );
}
export default CollectionsPageContainer;
