import React from "react";
import {Navigation} from "../../common/components/general/Navigation";
import {Link, useHistory} from "react-router-dom";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {Tooltip} from "../../common/components/general/Tooltip";
import {LanguagePicker} from "../../common/components/general/LanguagePicker";
import {MobileHeader} from "../../common/components/general/MobileHeader";
import {useAppLocation, useAppSelector} from "../../app/hooks";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {StringTranslation} from "../../common/utils/StringTranslation";
import {useDispatch} from "react-redux";
import {setShowIntroduction} from "../../app/rootReducer";
import {Introduction} from "../../common/components/general/Introduction";


interface Props {
    children?: React.ReactNode;
}

export const Wrapper = (props: Props) => {

    const location = useAppLocation()
    let history = useHistory()
    const dispatch = useDispatch();

    const {data} = useGetUserInfoQuery()

    const userData = useGetUserInfoQuery()

    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: translations} = useGetTranslationsQuery()

    const {firstName, insertion, lastName, dealer} = data || {firstName: '', insertion: '', lastName: ''}
    const dealerName = dealer?.name

    const {menuToggle} = useAppSelector((state) => state.root)

    if (location.pathname === '/') {
        history.push(RouteEnum.PRODUCT_RANGE)
    }



    const handleTooltipClick = () => {
        localStorage.setItem('introductionSlide', 'true');
        dispatch(setShowIntroduction(true));
    };

    return (
        <section className={"l-app" + (location.pathname === '/dashboard' ? ' is-active ' : '') + (menuToggle ? ' menu-open' : '')}>
            <Introduction/>
            <MobileHeader/>
            <div className="l-app__menu-overlay"></div>
            <aside className="l-app__menu">
                <div className="l-app__menu__content">
                    <Link to={RouteEnum.PRODUCT_RANGE} className="l-app__logo">
                        <img src="/images/Bravilor-logo.svg" alt="logo"/>
                    </Link>
                    <div className="l-app__menu__search-bar">
                        <input type="text" placeholder={StringTranslation('search-for-machines-accessories-or-parts', translations, activeLanguage)}/>
                        <div className="l-app__menu__search-bar__icon"><img src="/images/search.svg" alt="search"/></div>
                    </div>
                    <Navigation/>
                </div>
                <div className="l-app__menu__content">
                    <div className="l-app__menu__account">
                        <div className="l-app__menu__account__info">
                            {!!firstName && !!lastName &&
                                <span className="l-app__menu__account__name">{firstName} {insertion} {lastName}</span>
                            }
                            {!!dealerName &&
                                <span className="l-app__menu__account__company">{dealerName}</span>
                            }
                        </div>
                        <span className="log-out" onClick={() => {
                            localStorage.clear()
                            window.location.href = '/'
                        }}>{StringTranslation('log-out', translations, activeLanguage)}</span>
                    </div>
                    <div className="l-app__menu__translate">
                        <LanguagePicker/>
                        <Tooltip text={"Introduction"} onClick={handleTooltipClick} />
                    </div>
                </div>
            </aside>
            <main className="l-app__content">
                {props.children}
            </main>

        </section>
    )

}
