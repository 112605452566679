import {BrowserRouter, Route, Switch} from "react-router-dom";
import {RouteEnum} from "../common/enums/RouteEnum";
import {useAppSelector} from "./hooks";
import {Wrapper} from "../wrappers/app/Wrapper";
import ProductRangePageContainer from "../pages/productRangePage/ProductRangePageContainer";
import ProductDetailsPageContainer from "../pages/productDetail/ProductDetailsPageContainer";
import CollectionsPageContainer from "../pages/collectionsPage/CollectionsPageContainer";
import {DeleteCollectionModalContainer} from "../modals/deleteCollectionModal/DeleteCollectionModalContainer";
import {DeleteCollectionProductModalContainer} from "../modals/deleteCollectionProductModal/DeleteCollectionProductModalContainer";
import {EditCollectionModalContainer} from "../modals/editCollectionModal/EditCollectionModalContainer";
import {AddCollectionProductsModalContainer} from "../modals/addCollectionProductsModal/AddCollectionProductsModalContainer";
import PriceBookPageContainer from "../pages/priceBookPage/PriceBookPageContainer";
import {AddCollectionModalContainer} from "../modals/addCollectionModal/AddCollectionModalContainer";
import {ProductGalleryModal} from "../modals/productGalleryModal/ProductGalleryModal";
import {MoonLoader} from "react-spinners";
import SearchPageContainer from "../pages/searchPage/SearchPageContainer";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "./rootReducer";
import {useContext} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

const App = () => {

    const {
        deleteCollectionModal,
        deleteCollectionProductModal,
        editCollectionModal,
        addCollectionProductsModal,
        addCollectionModal
    } = useAppSelector(state => state);

    const {login} = useContext<IAuthContext>(AuthContext)

    const {data: translationsData,  isError: translationsError} = useGetTranslationsQuery()
    const {data: userInfoData, isError: userInfoError} = useGetUserInfoQuery()

    if ((!translationsData && !!translationsError) || (!userInfoData && !!userInfoError)) {
        localStorage.clear()
        login()
    }

    if (!!translationsData && !!userInfoData) {

        return (
            <BrowserRouter>

                <Wrapper>

                    <Switch>

                        {/*<Route path={RouteEnum.DASHBOARD} exact={true}>*/}
                        {/*    <DashboardPageContainer/>*/}
                        {/*</Route>*/}

                        <Route path={RouteEnum.PRODUCT_RANGE} exact={true}>
                            <ProductRangePageContainer/>
                        </Route>

                        <Route path={RouteEnum.COLLECTIONS} exact={true}>
                            <CollectionsPageContainer/>
                        </Route>

                        <Route path={RouteEnum.PRODUCT_RANGE + "/:productId"} exact={true}>
                            <ProductDetailsPageContainer/>
                        </Route>

                        {/*<Route path={RouteEnum.ACCOUNT} exact={true}>*/}
                        {/*    <AccountPageContainer/>*/}
                        {/*</Route>*/}

                        <Route path={RouteEnum.PRICEBOOK} exact={true}>
                            <PriceBookPageContainer/>
                        </Route>

                        <Route path={RouteEnum.SEARCH} exact={true}>
                            <SearchPageContainer/>
                        </Route>

                    </Switch>

                    <ProductGalleryModal/>

                    {deleteCollectionModal.collectionId && <DeleteCollectionModalContainer/>}
                    {deleteCollectionProductModal.collectionId && <DeleteCollectionProductModalContainer/>}
                    {editCollectionModal.collectionId && <EditCollectionModalContainer/>}
                    {addCollectionProductsModal.collectionId && <AddCollectionProductsModalContainer/>}
                    {addCollectionModal.selectedProductIds && <AddCollectionModalContainer/>}

                </Wrapper>

            </BrowserRouter>
        );
    } else return (
        <main className="l-app__content" style={{height: '100vh', alignItems: "center"}}>
            <div className={'c-loader'}>
                <MoonLoader color={'#E52713FF'}/>
            </div>
        </main>
    )
}

export default App;
