import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {ApiEnum} from "../../common/enums/ApiEnum";
import {apiBaseQuery} from "../../common/utils/apiBaseQuery";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductInterface} from "../../common/interfaces/ProductInterface";

interface SearchPageInterface {
    machinery: ProductInterface[]
    accessories: ProductInterface[]
    archived: ProductInterface[]
    resultInputValue: string
    resultCount: number
    isLoading: boolean
}

const initialState: SearchPageInterface = {
    machinery: [],
    accessories: [],
    archived: [],
    resultInputValue: "",
    resultCount: 0,
    isLoading: false
}


const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        mapResults: (state, action: PayloadAction<ProductInterface[] | undefined>) => {
            if (action.payload) {

                action.payload.forEach((product: ProductInterface) => {

                    if (product.productState === 'Archive'){
                        state.archived = [...state.archived, product]
                        return
                    }

                    if (product.productFamily.code === 'accessories') {
                        state.accessories = [...state.accessories, product]
                        return
                    }

                    state.machinery = [...state.machinery, product]

                });
            }
        },
        setSearchingVariables: (state, action: PayloadAction<{
            resultValue?: string,
            isLoading?: boolean,
            resultCount?: number,
            clearResults?: boolean
        }>) => {
            if (action.payload.resultValue) {
                state.resultInputValue = action.payload.resultValue
            }

            if (typeof action.payload.isLoading === 'boolean') {
                state.isLoading = action.payload.isLoading
            }

            if (typeof action.payload.resultCount === 'number') {
                state.resultCount = action.payload.resultCount
            }

            if (action.payload.clearResults) {
                state.machinery = []
                state.accessories = []
                state.archived = []
            }
        },
    },
})

export const searchApiSlice = createApi({
    reducerPath: "searchApi",
    baseQuery: apiBaseQuery(),
    tagTypes: [],
    endpoints: (builder) => ({
        searchProducts: builder.query<[], string>({
            query: (searchParam: string) => ApiEnum.PRODUCT_DETAILS + "?query=" + searchParam
        }),
    }),
});

export const {mapResults, setSearchingVariables} = searchSlice.actions;

export const searchReducer = searchSlice.reducer;

export const {useLazySearchProductsQuery} = searchApiSlice;
