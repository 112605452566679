import {CategoryListInterface} from "../../interfaces/CategoryListInterface";
import {Translate} from "../../utils/Translate";
import {useState} from "react";
import {useAppSelector} from "../../../app/hooks";
import {ProductListItem} from "./ProductListItem";
import {ProductGridItem} from "./ProductGridItem";
import {useGetUserInfoQuery} from "../../../app/rootReducer";

interface Props {
    child: CategoryListInterface;
    disableCheckbox?: boolean;
}

export const CategorySubItem = (props: Props) => {
    const {child} = props;
    const {products} = child;

    const [active, setActive] = useState(false)

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}

    const {gridLayout} = useAppSelector(state => state.productRange);


    return (

        <div className={"c-products-list__sub-item " + (active ? 'is-active' : '')}>
            <div className="c-products-list__sub-item__header" onClick={() => setActive(!active)}>
                <div className="c-products-list__sub-item__header__title">
                    <img src="/images/arrow-black.svg" alt="arrow"/>
                    {!!activeLanguage &&
                        <span>{Translate(child.label, activeLanguage)}</span>
                    }
                </div>
                <div className="c-products-list__sub-item__header__icon">
                    <span></span>
                    <span></span>
                </div>
            </div>


            {products && products.length > 0 &&
                <div
                    className={"c-products-list__sub-item__items " + (!!gridLayout ? 'c-products-list__sub-item__items--grid' : '')}>
                    {products.map((product) => {
                        if (!!gridLayout) {
                            return (
                                <ProductGridItem key={'grid-' + product.id} product={product}
                                                 disableCheckbox={props.disableCheckbox}/>
                            )
                        }
                        return (
                            <ProductListItem key={'list-' + product.id} product={product}
                                             disableCheckbox={props.disableCheckbox}/>
                        )
                    })}
                </div>
            }


        </div>
    )
}




