import {Key, useEffect, useState} from "react";
import {useGetUserInfoQuery, useLazyGetUserInfoQuery, useSetUserLanguageMutation} from "../../../app/rootReducer";
import {LanguageInterface} from "../../interfaces/LanguageInterface";

export const LanguagePicker = () => {

    const [active, setActive] = useState(false);

    const [setUserLanguageTrigger] = useSetUserLanguageMutation()
    const [getUserInfoTrigger] = useLazyGetUserInfoQuery()

    const {data} = useGetUserInfoQuery()
    const {languages, activeLanguage} = data || {languages: [], activeLanguage: null}

    const [activeLanguageState, setActiveLanguageState] = useState<LanguageInterface | null>(activeLanguage)

    useEffect(() => {
        setActiveLanguageState(activeLanguage)
    }, [activeLanguage])

    if (languages && languages.length > 1 && activeLanguageState) {
        return (
            <div className={"c-language-picker " + (active ? "is-active" : "")}>
                <button className="c-language-picker__btn" onClick={() => setActive(!active)}>
                    <div><span>{activeLanguageState.name}</span></div>
                    <img className="img-arrow" src="/images/arrow-down.svg" alt=""/>
                </button>
                <div className="c-language-picker__container">
                    <ul>
                        {languages.filter((language: LanguageInterface) => language.id !== activeLanguageState.id).map((language: LanguageInterface, index: Key | null | undefined) => (
                            <li key={index} onClick={() => {
                                setActive(!active)
                                setLanguage(language, getUserInfoTrigger, setUserLanguageTrigger, setActiveLanguageState)

                            }}><span>{language.name}</span></li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    } else {
        return null
    }

}


const setLanguage = (language: LanguageInterface, getUserInfoTrigger: any, setUserLanguageTrigger: any, setActiveLanguageState: any) => {
    setActiveLanguageState(language)
    setUserLanguageTrigger(language.id).then(() => {
        getUserInfoTrigger()
    })
}
