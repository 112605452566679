import {LanguageInterface} from "../interfaces/LanguageInterface";
import {Translate} from "./Translate";

export const StringTranslation = (key: string, texts: any, activeLanguage: LanguageInterface | null) => {

    if (activeLanguage && texts && texts[key]) {
        return Translate(texts[key], activeLanguage)
    } else {
        return 'No translation available for this key.'
    }
}
