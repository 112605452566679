import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {ApiEnum} from "../common/enums/ApiEnum";
import {userInterface} from "../common/interfaces/UserInterface";
import {apiBaseQuery} from "../common/utils/apiBaseQuery";


export interface RootInterface {
    menuToggle: boolean
    authToken?: string,
    showIntroduction: boolean
}

const initialState: RootInterface = {
    menuToggle: false,
    showIntroduction: !localStorage.getItem('introductionSlide')
}

export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        menuToggle: (state) => {
            state.menuToggle = !state.menuToggle

            if (state.menuToggle && document.body.clientWidth <= 1100) {
                document.body.style.overflow = "hidden"
            }
            if (!state.menuToggle && document.body.clientWidth <= 1100) {
                document.body.style.overflow = "auto"
            }
        },
        setAuthToken: (state, action: PayloadAction<string>) => {
            state.authToken = action.payload
        },
        setShowIntroduction: (state, action: PayloadAction<boolean>) => {
            state.showIntroduction = action.payload;
        }
    },
})

export const rootApiSlice = createApi({
    reducerPath: "rootApi",
    baseQuery: apiBaseQuery(),
    tagTypes: [],
    endpoints: (builder) => ({
        getUserInfo: builder.query<userInterface, void>({
            query: () => ({
                url: ApiEnum.USER,
            }),
        }),
        setUserLanguage: builder.mutation<userInterface, number>({
            query: (languageId) => ({
                url: ApiEnum.USER,
                method: 'PATCH',
                body: {activeLanguageId: languageId}
            }),
        }),
        getTranslations: builder.query<any, void>({
            query: () => ({
                url: ApiEnum.TRANSLATIONS + '/b2b_dealer',
            }),
        }),
    }),
});

export const {menuToggle, setAuthToken, setShowIntroduction} = rootSlice.actions

export default rootSlice.reducer

export const {
    useGetUserInfoQuery,
    useSetUserLanguageMutation,
    useGetTranslationsQuery,
    useLazyGetUserInfoQuery,
} = rootApiSlice;
