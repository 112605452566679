
export const breakPointsBannerSlider = [
    {width: 200, itemsToShow: 1.2, itemPadding: [1, 10],outerSpacing: 0},
    {width: 600, itemsToShow: 1.5, itemPadding: [1, 10],outerSpacing: 0},
    {width: 768, itemsToShow: 2.2, itemPadding: [1, 10], outerSpacing: 0},
    {width: 980, itemsToShow: 3.2, itemPadding: [1, 10], outerSpacing: 0},
    {width: 1024, itemsToShow: 3.4, itemPadding: [1, 10], outerSpacing: 0},
    {width: 1200, itemsToShow: 3.4, itemPadding: [1, 10], outerSpacing: 0},
];


export const breakPointsRelatedSlider = [
    {width: 100, itemsToShow: 1, itemPadding: [0, 0], outerSpacing: 0},
    {width: 400, itemsToShow: 1, itemPadding: [0, 0], outerSpacing: 0},
    {width: 500, itemsToShow: 2.92, itemPadding: [0, 7.5], outerSpacing: -7.5},
];
