import {createSlice} from '@reduxjs/toolkit'

interface AccountInterface {
    loading: boolean,
}

const initialState: AccountInterface = {
    loading: false,
}


const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {

    },
})

export const accountReducer = accountSlice.reducer
