import {ProductDetailsDataInterface} from "../../interfaces/ProductDetailsDataInterface";
import {LanguageInterface} from "../../interfaces/LanguageInterface";
import {useGetTranslationsQuery} from "../../../app/rootReducer";
import {StringTranslation} from "../../utils/StringTranslation";
import {Translate} from "../../utils/Translate";
import {convertUnitToAbbreviation} from "../../utils/convertUnitToAbbreviation";
import {MetricValue} from "../../interfaces/MetricValueInterface";

interface Props {
    data: ProductDetailsDataInterface,
    activeLanguage: LanguageInterface,
}

export const ProductSpecifications = (prop: Props) => {

    const {data, activeLanguage} = prop;
    const {data: translations} = useGetTranslationsQuery();

    const {productAttributes, dimensions, identifier} = data

    return (
        <div className="c-product-specs">
            <div className="header-title">
                <h2>{StringTranslation('specifications', translations, activeLanguage)}</h2>
            </div>
            <div className="c-product-specs__container">

                {!!dimensions &&
                    <div className="c-product-specs__item">
                        <div className="item-text-header">
                            {StringTranslation('dimensions', translations, activeLanguage)}
                        </div>
                        <div className="item-text-beverages">
                            <span>{dimensions}</span>
                        </div>
                    </div>
                }

                {productAttributes?.map((item, index) => (
                    RenderAttributes(item, identifier, activeLanguage, translations, index)
                ))}

            </div>
        </div>
    )
}

function RenderAttributes(item: any, identifier: string, activeLanguage: LanguageInterface, translations: any, index: number) {
    switch (true) {
        case (!!item.value && Object.values(item.value).length > 0 && Object.values(item.value)[0] !== null && item.type !== 'boolean' && item.type !== 'text' && item.type !== 'metric'):
            return (
                <div className="c-product-specs__item" key={index}>
                    <div className="item-text-header">
                        {Translate(item.label, activeLanguage)}
                    </div>
                    <div className="item-text-beverages">
                        {Object.values(item.value).length > 1 ?
                            <ul>
                                {Object.values(item.value).map((value, index) => (
                                    <li key={index}>{Translate(value, activeLanguage)}</li>
                                ))}
                            </ul>
                            :
                            <span>{Translate(Object.values(item.value)[0], activeLanguage)}</span>
                        }
                    </div>
                </div>
            )
        case (!!item.value && item.type === 'boolean' && Object.values(item.value)[0] !== null):
            return (
                <div className="c-product-specs__item" key={index}>
                    <div className="item-text-header">
                        {Translate(item.label, activeLanguage)}
                    </div>
                    <div className="item-text-beverages">
                        <span>{StringTranslation(Object.values(item.value)[0] ? 'yes' : 'no', translations, activeLanguage)}</span>
                    </div>
                </div>
            )
        case (!!item.value && item.type === 'text'):
            return (
                <div className="c-product-specs__item" key={index}>
                    <div className="item-text-header">
                        {Translate(item.label, activeLanguage)}
                    </div>
                    <div className="item-text-beverages">
                        <span>{Translate(item.value, activeLanguage)}</span>
                    </div>
                </div>
            )
        case (!!item.value && item.type === 'metric' && Object.values(item.value)[0] !== null):
            let {amount, unit} = Object.values(item.value)[0] as MetricValue
            amount = amount.replace(/\.0000$/, '')
            unit = convertUnitToAbbreviation(unit)
            return (
                <div className="c-product-specs__item" key={index}>
                    <div className="item-text-header">
                        {Translate(item.label, activeLanguage)}
                    </div>
                    <div className="item-text-beverages">
                        <span>{amount} {unit}</span>
                    </div>
                </div>
            )
        case (item.type === 'identifier'):
            return (
            <div className="c-product-specs__item" key={index}>
                <div className="item-text-header">
                    {Translate(item.label, activeLanguage)}
                </div>
                <div className="item-text-beverages">
                    <span>{identifier}</span>
                </div>
            </div>
        )
        default:
            return null;
    }
}
