import {useGetPriceBookQuery} from "./PricebookSlice";
import {Searchbar} from "../../common/components/general/Searchbar";
import {useGetTranslationsQuery, useGetUserInfoQuery} from "../../app/rootReducer";
import {MoonLoader} from "react-spinners";
import {StringTranslation} from "../../common/utils/StringTranslation";
import {DownloadItem} from "../../common/components/general/DownloadItem";
import {PriceBookInterface} from "../../common/interfaces/PriceBookInterface";

const PriceBookPageContainer = () => {

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}
    const {data: downloads, isLoading} = useGetPriceBookQuery()
    const {data: translations} = useGetTranslationsQuery();

    const downloadsAmount = downloads?.length || 0

    let downloadTitle = StringTranslation('downloads', translations, activeLanguage)
    if (downloadsAmount === 1) {
        downloadTitle = StringTranslation('download', translations, activeLanguage)
    }

    if (!isLoading) {
        return (
            <>
                <div className="p-pricebook">
                    <div className="p-pricebook__header">
                        <div className="c-breadcrumbs">
                            <span>{StringTranslation('pricebook', translations, activeLanguage)}</span>
                            <div className="c-breadcrumbs__arrow"><img src="/images/arrow-black.svg" alt="arrow"/></div>
                            <span>{StringTranslation('downloads', translations, activeLanguage)}</span>
                        </div>
                        <Searchbar activeLanguage={activeLanguage}/>
                    </div>

                    <div className="p-pricebook__content">

                        <div className="p-pricebook__content__header">
                            <h2>{downloadsAmount} {downloadTitle}</h2>
                        </div>

                        {downloads && downloads.length > 0 &&
                            <div className="p-pricebook__content__downloads">
                                {downloads.map((item: PriceBookInterface, key: number) => (
                                    <DownloadItem key={key} data={item} activeLanguage={activeLanguage}/>
                                ))}
                            </div>
                        }

                    </div>

                </div>
            </>
        )
    } else {
        return (
            <div className={"c-loader"}>
                <MoonLoader color={"#E52713FF"}/>
            </div>
        );
    }
}

export default PriceBookPageContainer;

