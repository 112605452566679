import {ProductCarouselNavigationItem} from "./ProductCarouselNavigationItem";
import {breakPointsBannerSlider} from "./BreakPointsCarousel";
import {Image, ProductDetailsDataInterface} from "../../interfaces/ProductDetailsDataInterface";
import {ProductCarouselItem} from "./ProductCarouselItem";
import Carousel from "react-elastic-carousel";
import {useAppDispatch} from "../../../app/hooks";
import {setActive, setLightboxSlices} from "../../../modals/productGalleryModal/productGalleryModalSlice";

interface Props {
  data: ProductDetailsDataInterface
}

export const ProductCarousel = (props: Props) => {

  const {data} = props;
  const dispatch = useAppDispatch();

  return (
    <div className="c-product-carousel">
      <Carousel
        isRTL={false}
        pagination={false}
        renderArrow={ProductCarouselNavigationItem}
        breakPoints={breakPointsBannerSlider}
        enableSwipe={false}
        enableMouseSwipe={false}
      >
        {data.productImages.map((slice: Image, index: number) =>
          <ProductCarouselItem
            key={index}
            isVideo={slice.type === 'video'}
            imageUrl={slice.type === 'video' ? slice.url : slice.converted.HD}
            handleOnClick={() => {
              dispatch(setLightboxSlices(data.productImages))
              dispatch(setActive(true))
            }}
          />
        )}
      </Carousel>
    </div>
  )

}
